import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import t from '@jetshop/intl';
import { styled } from 'linaria/react';

import { theme } from './Theme';
import Button from './ui/Button';

const animationLength = 800;

const Wrapper = styled('div')`
   @media (max-width: ${theme.breakpoints.sm}) {
      left: 0;
      bottom: 0;
      width: 100%;
   }

   position: fixed;
   left: 1rem;
   bottom: 1.5rem;
   padding: var(--spacing-m);
   background-color: white;
   width: 24rem;
   border: 1px solid var(--beige-1);
   max-width: 100%;
   opacity: 0;
   animation-name: enter;
   animation-duration: ${animationLength}ms;
   animation-fill-mode: forwards;
   animation-iteration-count: 1;
   z-index: 50;
   &.unmounting {
      animation-name: exit;
   }

   @keyframes enter {
      0% {
         opacity: 0;
      }
      100% {
         opacity: 1;
      }
   }

   @keyframes exit {
      0% {
         opacity: 1;
      }
      100% {
         opacity: 0;
      }
   }

   a {
      display: block;
      margin-bottom: 1.5rem;
   }

   p {
      margin-bottom: 1rem;
   }

   button {
      width: 100%;
      height: 46px;
   }
`;

const CookieConsent = ({ cookies }) => {
   const { cookieConsent } = cookies.cookies;

   const [visible, setVisible] = useState(cookieConsent !== 'agreed');
   const [unmounting, setUnmounting] = useState(false);

   function agree() {
      cookies.set('cookieConsent', `agreed`);
      setUnmounting(true);
      setTimeout(() => {
         setVisible(false);
      }, animationLength);
   }

   return visible ? (
      <Wrapper className={unmounting ? 'unmounting' : ''}>
         <p>
            {t(
               'Vi använder cookies för att denna sajt skall fungera bättre för dig.',
            )}
         </p>
         <Link to="/terms-and-conditions">
            {t('Läs mer om vår cookie-policy.')}
         </Link>
         <Button onClick={agree}>{t('OK!')}</Button>
      </Wrapper>
   ) : null;
};

export default withCookies(CookieConsent);
