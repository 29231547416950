import React from 'react';
import CategoryMenuContainer from './CategoryMenuContainer';

export function CategoryMenu({ data }) {
  return (
    <nav>
      <div key={'menuContainerPosed'} className="menu-container">
        <CategoryMenuContainer queryData={data} />
      </div>
    </nav>
  );
}
