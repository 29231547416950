import React from 'react';
import { Link } from 'react-router-dom';

import t from '@jetshop/intl';
import { cx } from 'linaria';
import { styled } from 'linaria/react';

import { theme } from '../Theme';
import Spinner from './Spinner';

export const baseStyles = `
  background-color: var(--beige-2);
  border-color: var(--beige-2);
  color: var(--vit);
  font-size: var(--knapptext);
  padding: 0.83rem 1.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.4;
  border-radius: 0;

  span {
    margin-right: 12px;
  }

  svg {
    fill: var(--vit);
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }

  ${theme.above.md} {
    max-width: 100%;
  }

  :disabled,
  &[disabled] {
    border: 1px solid #dedede;
    opacity: 0.5;
  }

  &.secondary {
    background-color: var(--vit);
    color: ${theme.colors.darkgrey};
    circle.path {
      stroke: black;
    }
  }

  &.light {
    background: var(--beige-1);
    color: var(--beige-4);
  }

  &.dark {
	background: var(--beige-4);
	color: white;
 }

  &.small {
    height: 45px;
  }
`;

const Button = styled('button')`
   ${baseStyles};
`;

export const ButtonLink = styled(Link)`
   ${baseStyles};
   text-decoration: none;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const StyledSpinner = styled(Spinner)`
   height: 25px;
   circle.path {
      stroke: var(--vit);
   }
`;

const ButtonWithLoading = ({
   loading,
   loadingText = t('Hold on a moment...'),
   secondary,
   light,
   dark,
   small,
   children,
   ...props
}) => {
   return (
      <Button
         {...props}
         className={cx(
            props.className,
            secondary && 'secondary',
            small && 'small',
            light && 'light',
            dark && 'dark',
         )}
      >
         {loading && (
            <>
               <span>{loadingText}</span>
               <StyledSpinner />
            </>
         )}

         {!loading && children}
      </Button>
   );
};

export default ButtonWithLoading;
