import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as FacebookLogo } from '../../../svg/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../../svg/instagram.svg';
import { theme } from '../../Theme';

const Wrapper = styled('div')`
   margin-right: 0;
   ul {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      flex-direction: row;
      justify-content: flex-start;
   }

   li {
      margin: 0 1.5rem 0 0;
   }

   a.social-links {
      display: flex;
      justify-content: flex-end;
      align-items: center;
   }

   color: ${theme.colors.black};
`;

const LogoWrapper = styled('span')`
  //background: ${theme.colors.black};
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const SocialLinks = ({ showLabel = true }) => (
   <Wrapper>
      <ul>
         <li>
            <a
               className="social-links"
               aria-label="Facebook"
               target="_blank"
               rel="noreferrer"
               href="https://www.facebook.com/Getrefilled-110349637818857"
            >
               <LogoWrapper>
                  <FacebookLogo />
               </LogoWrapper>
               {showLabel && <label>Facebook</label>}
            </a>
         </li>
         <li>
            <a
               className="social-links"
               aria-label="Instagram"
               target="_blank"
               rel="noreferrer"
               href="https://www.instagram.com/get.refilled/"
            >
               <LogoWrapper>
                  <InstagramLogo />
               </LogoWrapper>
               {showLabel && <label>Instagram</label>}
            </a>
         </li>
      </ul>
   </Wrapper>
);

export default SocialLinks;
