import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { styled } from 'linaria/react';
import React, { Fragment } from 'react';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import Badge from '../ui/Badge';
import cartQuery from './CartQuery.gql';
import { IconLabel } from '../Layout/Header/Header';
import t from '@jetshop/intl';

const Button = styled('button')`
	padding: 0;
	background: transparent;
	color: inherit;
	border: 0;
	outline: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

function CartButton({ target, itemsInCart, className }) {
	return (
		<div className={className}>
			<Button
				data-testid="cart-button"
				onClick={target.isOpen ? target.hideTarget : target.showTarget}
			>
				<Cart className="badge-svg-wrapper" />
				<IconLabel>{t('Cart')}</IconLabel>
			</Button>
			{itemsInCart > 0 && <Badge text={itemsInCart} />}
		</div>
	);
}

function CartButtonFlyout({ className }) {
	return (
		<Fragment>
			<CartProvider query={cartQuery}>
				{result => {
					// Set items in cart to the API result.
					// If the result is undefined, fall back to 0
					const itemsInCart = result?.data?.cart?.totalQuantity || 0;

					return (
						// Use a flyout for breakpoints above md
						// Else use a Drawer
						<Above breakpoint="md">
							{matches =>
								matches ? (
									<FlyoutTrigger id="cart-flyout">
										{flyout => (
											<CartButton
												className={className}
												target={flyout}
												itemsInCart={itemsInCart}
											/>
										)}
									</FlyoutTrigger>
								) : (
									<DrawerTrigger
										preventOverflow={true}
										id="cart-drawer"
									>
										{drawer => (
											<CartButton
												className={className}
												target={drawer}
												itemsInCart={itemsInCart}
											/>
										)}
									</DrawerTrigger>
								)
							}
						</Above>
					);
				}}
			</CartProvider>
		</Fragment>
	);
}

export default CartButtonFlyout;
