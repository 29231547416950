import React, { useEffect, useRef } from 'react';

import { styled } from 'linaria/react';

const SnappptWrapper = styled('div')``;

export const Snapppt = () => {
   const snappptRef = useRef();

   useEffect(() => {
      const script = document.createElement('script');

      script.src =
         'https://snapppt.com/widgets/widget_loader/667d170c-2f7b-e537-8ea1-94bb0a5cd73e/sauce_insta_shop.js';
      script.async = true;
      script.classList.add('snapppt-widget');

      snappptRef.current.appendChild(script);

      return () => {
         snappptRef.current.removeChild(script);
      };
   }, [snappptRef]);

   return <SnappptWrapper ref={snappptRef} />;
};

export default Snapppt;
