import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';

import CartButton from '../../Cart/CartButton';
import TopBanner from '../../ContentElements/TopBanner';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import { theme } from '../../Theme';
import MaxWidth, { MaxWidthNoFlex } from '../MaxWidth';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import { IconLabel } from './Header';
import { Logo } from './Logo';
import MobileMenu from './MobileMenu';
import { SearchBar } from './SearchBar';

import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import { ReactComponent as Person } from '../../../svg/Person.svg';
import { ReactComponent as SearchIcon } from '../../../svg/Search.svg';

export const MenuButton = styled('button')`
	display: flex;
	flex-direction: column;
	font-size: var(--text-size-small);
	background: transparent;
	align-items: center;
	color: ${theme.colors.darkgrey};
	svg {
		height: 18px;
		width: 18px;
	}
	:focus,
	:active,
	:hover {
		outline: none;
		opacity: 0.8;
		color: ${theme.colors.black};
	}
`;

const SearchTrigger = styled.div`
	cursor: pointer;
`;

const IconButtons = styled.div`
	display: grid;
	column-gap: 18px;
	grid-auto-flow: column;
	justify-items: center;

	a {
		color: ${theme.colors.darkgrey};
	}

	*:focus,
	*:active,
	*:hover {
		color: ${theme.colors.black};
		text-decoration: underline;
	}
`;

const DesktopWrapper = styled(MaxWidth)`
	flex-direction: initial;
	position: initial;
	height: 85px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const iconButton = css`
	display: grid;
	justify-items: center;
	grid-template-columns: 1fr;
	grid-template-rows: minmax(24px, 1fr);
	grid-auto-flow: row;
	grid-template-auto-rows: 1fr;
	align-items: end;
	position: relative;
	color: ${theme.colors.darkgrey};

	span {
		font-size: 12px;
	}

	svg {
		height: 22px;
		width: 22px;
	}

	.badge {
		position: absolute;
		@media (min-width: ${theme.breakpoints.md}) {
			left: calc(100% - 12px);
		}
		left: calc(100% - 2px);
		top: -10px;
		pointer-events: none;
		color: var(--text-color);
		font-size: 8px;
	}
`;

const favouriteCountButton = css`
	.badge {
		left: 70%;
	}
`;

const MobileWrapper = styled(MaxWidthNoFlex)`
	display: flex;
	column-gap: 8px;
	padding: 0 20px;
	height: 64px;
	align-items: center;
	position: relative;

	.mobile-menu {
		top: 0;

		.top-banner ~ & > * {
			top: 30px !important;
		}
	}
`;

const SubGrid = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 20px;
	align-items: end;
	padding-bottom: 2px;

	color: ${theme.colors.darkgrey};

	a {
		color: ${theme.colors.darkgrey};
	}
`;

const MenuTrigger = () => (
	<DrawerTrigger preventOverflow={true} id="menu-drawer">
		{drawer => (
			<>
				<MenuButton
					onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
				>
					<Menu />
					<IconLabel>{t('Menu')}</IconLabel>
				</MenuButton>
			</>
		)}
	</DrawerTrigger>
);

const Mobile = ({ result, toggleSearch }) => {
	return (
		<>
			<TopBanner className="top-banner" data-top-banner />
			<MobileWrapper>
				<SubGrid>
					<MenuTrigger />
					<SearchTrigger
						className={iconButton}
						onClick={() => toggleSearch()}
					>
						<SearchIcon />
						<IconLabel>{t('Search')}</IconLabel>
					</SearchTrigger>
				</SubGrid>

				<Logo />

				<SubGrid>
					<div>
						<FavouriteCount className={iconButton} />
					</div>
					<div>
						<CartButton className={iconButton} />
					</div>
				</SubGrid>

				<div className="mobile-menu">
					<MobileMenu data={result?.data} />
				</div>
			</MobileWrapper>
		</>
	);
};

const DesktopLeft = styled.div`
	display: grid;
	grid-auto-flow: column;
	column-gap: 54px;
	align-items: center;
`;

const Desktop = ({ result, toggleSearch }) => {
	const { loggedIn } = useAuth();
	const { routes } = useShopConfig();
	return (
		<>
			<TopBanner />
			<DesktopWrapper>
				<DesktopLeft>
					<Logo />
					<CategoryMenu data={result.data} />
				</DesktopLeft>

				<IconButtons>
					<SearchTrigger
						className={iconButton}
						onClick={() => toggleSearch()}
					>
						<SearchIcon />
						<IconLabel>Sök</IconLabel>
					</SearchTrigger>
					<Link className={iconButton} to={routes.myPages.path}>
						<Person />
						<IconLabel>
							{loggedIn ? t('Mina sidor') : t('Logga in')}
						</IconLabel>
					</Link>
					<FavouriteCount
						className={cx(iconButton, favouriteCountButton)}
					/>
					<div className={iconButton}>
						<CartButton className={iconButton} />
					</div>
				</IconButtons>
			</DesktopWrapper>
		</>
	);
};

export const Main = ({ result }) => {
	const [searchIsOpen, setSearchIsOpen] = useState();
	const context = {
		result,
		searchIsOpen,
		setSearchIsOpen,
		toggleSearch: () => setSearchIsOpen(!searchIsOpen),
		focusSearch: () => {
			document
				?.querySelector('[data-flight-search-autocomplete-field]')
				?.focus();
		},
	};

	return (
		<>
			<Above breakpoint="xl">
				{matches =>
					matches ? <Desktop {...context} /> : <Mobile {...context} />
				}
			</Above>

			<SearchBar {...context} />
		</>
	);
};
