import createFacebookTracker from '@jetshop/core/server/tracking/facebook';

import * as translations from '../translations';
import {
	LoadableCategoryPage,
	LoadableContentPage,
	LoadableProductPage,
} from './components/Shop';
import { theme } from './components/Theme';

import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import routeQuery from './components/RouteQuery.gql';

const config = {
	theme,
	apolloConfig: {
		shopid: process.env.REACT_APP_SHOP_ID || 'getrefilled',
		graphQLURI:
			process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io/',
		token:
			process.env.REACT_APP_APOLLO_TOKEN ||
			'359fd7c1-8e72-4270-b899-2bda9ae6ef57',
		engineApiKey: process.env.ENGINE_API_KEY || '',
		enableGateway: false,
		channelsQuery,
		persistedQueries: [
			{
				query: homeCategoriesQuery,
				variables: { levels: 1 },
			},
		],
	},
	siteSettingsId: process.env.SITE_SETTINGS_ID || 150,
	trackingID: process.env.REACT_APP_GA_TRACKING_ID,
	additionalGtagTrackingIds: [],
	tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
	relewareEnabled: true,
	sentry: {
		clientDSN: process.env.FLIGHT_SENTRY_CLIENT_DSN,
		serverDSN: process.env.FLIGHT_SENTRY_SERVER_DSN,
		ignoreErrors: [],
	},
	intl: {
		translations,
		defaultLocale: 'en',
		options: {},
	},
	channelOverrides: {},
	disableGeoRedirect: true,
	singleDomainMode: process.env.REACT_APP_SINGLE_DOMAIN_MODE,
	schemaExtensions: [],
	preserveRedirect: true,
	structuredData: {
		disableDefaultProductData: false,
	},
	openGraph: {
		disableDefaultProductData: false,
	},
	trackers: [],
	serverTrackers: [
		createFacebookTracker({
			pixelID: process.env.FACEBOOK_PIXEL_ID,
			token: process.env.FACEBOOK_CAPI_TOKEN,
		}),
	],
	googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
	loginPath: '/login',
	pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
	preload: {
		routeQuery,
		preloadComponents: {
			Product: [LoadableProductPage],
			Category: [LoadableCategoryPage],
			Page: [LoadableContentPage],
		},
	},
};
export default config;
