import { css } from 'linaria';

import GingerRegularWoff from './f37ginger-regular-webfont.woff';
import GingerRegularWoff2 from './f37ginger-regular-webfont.woff2';

import GingerBoldWoff from './f37ginger-bold-webfont.woff';
import GingerBoldWoff2 from './f37ginger-bold-webfont.woff2';

import GingerLightWoff from './f37ginger-light-webfont.woff';
import GingerLightWoff2 from './f37ginger-light-webfont.woff2';

import DMDisplayWoff from './dm-serif-display-v5-latin-ext_latin-regular.woff';
import DMDisplayWoff2 from './dm-serif-display-v5-latin-ext_latin-regular.woff2';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/dm-serif-display?subsets=latin,latin-ext

export default function loadCss() {
  return css`
    :global(html) {
      /* Ginger */
      @font-face {
        font-family: 'Ginger';
        src: local('F37Ginger Regular'), local('F37Ginger-Regular'),
          url(${GingerRegularWoff2}) format('woff2'),
          url(${GingerRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Ginger';
        src: local('F37Ginger Bold'), local('F37Ginger-Bold'),
          url(${GingerBoldWoff2}) format('woff2'),
          url(${GingerBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Ginger';
        src: local('F37Ginger Light'), local('F37Ginger-Light'),
          url(${GingerLightWoff2}) format('woff2'),
          url(${GingerLightWoff}) format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'DM Serif Display';
        font-style: normal;
        font-weight: 400;
        src: local(''), url(${DMDisplayWoff2}) format('woff2'),
          url(${DMDisplayWoff}) format('woff');
      }

      &.ginger-ready body {
        font-family: 'Ginger', sans-serif;
      }
    }
  `;
}
