import React from 'react';

import { styled } from 'linaria/react';

import useGlobalContent from '../../hooks/useGlobalContent';
import { stripHTML } from '../../utils';

const Wrapper = styled.div`
	text-align: center;
	font-family: var(--text-font);
	font-size: var(--text-size-small);
	line-height: 1;

	background-color: var(--svart, black);
	color: var(--vit, white);
	padding: 10px;
	width: 100%;
	height: 30px;
	text-transform: uppercase;
	z-index: 100000;
`;

/**
 * Uses text from first site setting  on page and displays in top banner
 */
const TopBanner = () => {
	const { getGlobalContent } = useGlobalContent();

	const text = getGlobalContent('topBanner');

	if (text?.length <= 0) return null;

	return <Wrapper className="top-banner">{stripHTML(text)}</Wrapper>;
};

export default TopBanner;
