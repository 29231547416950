import React from 'react';
import { useQuery } from 'react-apollo';

import { Notifications } from '../Notifications';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import CartFlyout from '../../Cart/CartFlyout';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { Main } from './HeaderMain';

export const IconLabel = styled.span`
	font-size: var(--text-size-small);

	@media (max-width: ${theme.breakpoints.md}) {
		display: none;
	}
`;

const Container = styled('header')`
	background-color: #fff;
	position: relative;
	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
			color: #000;
		}
	}
	${theme.below.xl} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

export const MenuButton = styled('button')`
	display: flex;
	flex-direction: column;
	font-size: var(--text-size-small);
	background: transparent;
	align-items: center;
	svg {
		height: 18px;
		width: 18px;
	}
	:focus,
	:active,
	:hover {
		outline: none;
		opacity: 0.8;
	}
	label {
		margin-top: 3px;
	}
`;

export const IconContainer = styled('span')`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	margin: 0;
`;

export default function Header() {
	const result = useQuery(homeCategoriesQuery, {
		variables: {
			levels: 1,
		},
	});

	return (
		<>
			<ChannelBanner />
			<Container>
				<Main result={result} />
				<Notifications />
			</Container>

			<CartFlyout />
		</>
	);
}
